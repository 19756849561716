import { Alert, Box, CardContent, LinearProgress, Tab } from '@mui/material'
import { DataGrid, FooterPropsOverrides, GridColDef, GridPaginationModel, GridRenderCellParams } from '@mui/x-data-grid'
import { useTerritoriesList } from '@src/data/api/territories-api/territories-api'
import { EFinancingCompany } from '@src/data/types/Constants'
import { TerritoryFilter } from '@src/data/types/Filter'
import { TerritoryRow } from '@src/data/types/Territory'
import translate from '@src/services/translate'
import { AxiosError } from 'axios'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridFooter } from '../../components'
import ActionCell from './components/ActionCell'
import MainActions from './MainActions'

import { useSelectedCompany } from '../../contexts/SelectedCompanyProvider'
import EditTerritoryDialog from './components/EditTerritoryDialog'

const Territories = (): JSX.Element => {
  const { t } = useTranslation()
  const [pagination, setPagination] = useState<GridPaginationModel>({
    page: 0,
    pageSize: 15,
  })
  const [openEditTerritoryDialog, setOpenEditTerritoryDialog] = useState(false)
  const [selectedRow, setSelectedRow] = useState<TerritoryRow | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const selectedCompany = useSelectedCompany()

  const handleEdit = useCallback((row: TerritoryRow) => {
    setSelectedRow(row)
    setOpenEditTerritoryDialog(true)
  }, [])

  const handleCloseProfile = useCallback(() => {
    setOpenEditTerritoryDialog(false)
  }, [])

  const columns = useMemo<GridColDef<TerritoryRow>[]>(
    (): GridColDef<TerritoryRow>[] => [
      {
        field: 'name',
        headerName: t('common.fullName'),
        flex: 1,
      },
      {
        field: 'actions',
        headerName: t('common.actions'),
        renderCell: (params: GridRenderCellParams<TerritoryRow>) => (
          <ActionCell row={params.row} handleEdit={handleEdit} setError={setError} />
        ),
        sortable: false,
        width: 150,
      },
    ],
    [handleEdit, t],
  )

  const query: TerritoryFilter = useMemo(() => {
    return {
      financingCompanyId: selectedCompany,
    } as TerritoryFilter
  }, [selectedCompany])

  const [territoriesList, isFetchingTerritoriesList] = useTerritoriesList(query)

  const totalRowCount = territoriesList.length

  const isPreviewDisabled = pagination.page === 0
  const isNextDisabled = (pagination.page + 1) * pagination.pageSize >= totalRowCount

  return (
    <>
      <Box sx={{ textAlign: 'center', height: '45rem' }}>
        {error !== null && (
          <Alert sx={{ mt: 1 }} severity="error">
            {translate(`common.errors.${(error as AxiosError<string>).response?.data}`)}
          </Alert>
        )}
        <CardContent>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: '30px' }}>
            <Tab label={t('common.territories')} />
            <MainActions disableButton={selectedCompany === EFinancingCompany.Iceberg} />
          </div>
          {isFetchingTerritoriesList ? (
            <LinearProgress style={{ height: '10px' }} />
          ) : (
            <DataGrid
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 15,
                  },
                },
              }}
              onPaginationModelChange={(model: GridPaginationModel) => setPagination(model)}
              pageSizeOptions={[15, 25, 50, 100]}
              disableColumnFilter
              paginationMode="client"
              rowCount={totalRowCount}
              rows={territoriesList}
              columns={columns}
              disableRowSelectionOnClick
              slots={{
                footer: DataGridFooter,
              }}
              slotProps={{
                footer: {
                  disabledPreview: isPreviewDisabled,
                  disabledNext: isNextDisabled,
                  pagination,
                  setPagination,
                } as FooterPropsOverrides,
              }}
            />
          )}
        </CardContent>
      </Box>
      <EditTerritoryDialog
        open={openEditTerritoryDialog}
        handleClose={handleCloseProfile}
        title={selectedRow ? selectedRow.name : t('common.newTerritory')}
        row={selectedRow}
      />
    </>
  )
}
export default Territories
