import { Button } from '@mui/material'
import { GridFooter, GridFooterContainer, GridPaginationModel } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'

interface Props {
  pagination: GridPaginationModel
  setPagination: (paginationn: GridPaginationModel) => void
  disabledPreview: boolean
  disabledNext: boolean
}

const Footer = ({ pagination, setPagination, disabledPreview, disabledNext }: Props) => {
  const { t } = useTranslation()
  return (
    <GridFooterContainer>
      <GridFooter sx={{ '& .MuiTablePagination-actions': { display: 'none' } }} />
      <div style={{ display: 'flex' }}>
        <Button onClick={() => setPagination({ ...pagination, page: pagination.page - 1 })} disabled={disabledPreview}>
          {t('common.previous')}
        </Button>
        <Button onClick={() => setPagination({ ...pagination, page: pagination.page + 1 })} disabled={disabledNext}>
          {t('common.next')}
        </Button>
      </div>
    </GridFooterContainer>
  )
}

export default Footer
