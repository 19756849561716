import { Container } from '@mui/material'
import { useUser } from '@src/contexts/AppContext'
import { Outlet } from 'react-router-dom'
import AlertBanner from './AlertBanner'
import AppHeader from './AppHeader'

const TemplateApp = () => {
  const user = useUser()
  const isIcebergGrDevs = user?.roles.includes('IcebergGrDevs') ?? false
  return (
    <>
      <AlertBanner message="Production" show={isIcebergGrDevs} />
      <AppHeader />
      <Container maxWidth="xl" sx={{ mt: 3, pb: 6, overflow: 'auto' }}>
        <Outlet />
      </Container>
    </>
  )
}

export default TemplateApp
