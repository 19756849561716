import {
  Box,
  IconButton,
  LinearProgress,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material'
import { formatDateTime } from '@src/containers/App/utils/Formatter'
import { MerchantReportSummaryDto, useForceGenerateReport } from '@src/data/api/merchants-api/merchants-api'
import { useTranslation } from 'react-i18next'
import AsyncActionButton from './AsyncActionButton'
import DownloadButton from './DownloadButton'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

interface Props {
  merchantId: string
  reportsSummary: MerchantReportSummaryDto[]
  isFetchingReports: boolean
  onGenerateReport: () => void
}

const ReportsList = ({ merchantId, reportsSummary, isFetchingReports, onGenerateReport }: Props) => {
  const { t } = useTranslation()
  const [forceGenerateReport, isPending, reset] = useForceGenerateReport()

  const handleforceGenerateReport = async () => {
    await forceGenerateReport(merchantId)
    reset()
    onGenerateReport()
  }

  return (
    <TableContainer component={Paper}>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <Typography variant="h6" gutterBottom component="div" sx={{ mt: 1, ml: 2, mr: 1, minWidth: '30vh' }}>
          {t('common.reports')}
        </Typography>
        <Box sx={{ flex: '1 1 350%' }} />
        <AsyncActionButton
          fullWidth
          variant="outlined"
          isPending={isPending || isFetchingReports}
          onClick={async () => {
            await handleforceGenerateReport()
          }}
        >
          {t('common.generateSendReport')}
        </AsyncActionButton>
      </Toolbar>
      <Table sx={{ maxHeight: '25rem' }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <StyledTableCell>{t('common.fileName')}</StyledTableCell>
            <StyledTableCell sx={{ textAlign: 'center' }}>{t('common.createdOn')}</StyledTableCell>
            <StyledTableCell sx={{ textAlign: 'center' }}>{t('common.download')}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(isFetchingReports || isPending) && (
            <TableRow>
              <TableCell colSpan={20} style={{ padding: 0 }}>
                <LinearProgress style={{ height: '10px' }} />
              </TableCell>
            </TableRow>
          )}
          {reportsSummary?.map((row) => (
            <TableRow key={row.fileName}>
              <TableCell>{row.fileName.replace(`Merchants/${merchantId}/`, '')}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>{formatDateTime(row.createdOn)}</TableCell>
              <TableCell sx={{ textAlign: 'center' }}>
                <span>
                  <IconButton>
                    <DownloadButton fileName={row.fileName} sasTokenUrl={row.sasTokenUrl} />
                  </IconButton>
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default ReportsList
