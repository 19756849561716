import {
  AppBar,
  Container,
  Toolbar,
  IconButton,
  useTheme,
  Link,
  ThemeProvider,
  createTheme,
  Theme,
  ThemeOptions,
} from '@mui/material'
import {
  Brightness7 as Brightness7Icon,
  ViewList as ViewListIcon,
  Brightness4 as Brightness4Icon,
} from '@mui/icons-material'
import { useContext } from 'react'
import { ColorModeContext } from '@src/contexts/ColorModeContext'
import { merge } from 'lodash-es'
import LanguagePicker from './LanguagePicker'
import AppMenu from './AppMenu'
import TerritoriesMenu from './TerritoriesMenu'
import UserMenu from './UserMenu'
import CompanySelector from './CompanySelector'

interface AppHeaderProps {
  hasAlertBanner?: boolean
}

const innerTheme = (theme: Theme) => {
  const iTheme: ThemeOptions = {
    components: {
      MuiButton: {
        defaultProps: {
          variant: 'text',
          color: 'inherit',
        },
      },
      MuiIconButton: {
        defaultProps: {
          color: 'inherit',
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
        styleOverrides: {
          icon: {
            color: 'inherit',
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            color: 'inherit',
          },
          root: {
            color: 'inherit',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            ':before': {
              borderColor: 'white',
            },
            ':hover': {
              ':before': {
                borderColor: 'white !important',
              },
            },
          },
        },
      },
    },
  }
  return createTheme(merge(theme, iTheme))
}

const Layout = ({ hasAlertBanner = false }: AppHeaderProps): JSX.Element => {
  const theme = useTheme()
  const colorMode = useContext(ColorModeContext)

  return (
    <AppBar
      position="sticky"
      sx={{ backgroundColor: 'rgb(0, 0, 89)', color: 'white', top: hasAlertBanner ? 48 : 0, mb: 10 }}
    >
      <Container maxWidth="xl" disableGutters>
        <ThemeProvider theme={innerTheme}>
          <Toolbar disableGutters>
            <div className="icebergBanner">
              <img src="/images/iceberg-no-bg.png" alt="Iceberg Finance" style={{ width: '110px', height: '40px' }} />
            </div>
            <Link href="/">
              <IconButton>
                <ViewListIcon sx={{ color: '#ffffff' }} />
              </IconButton>
            </Link>
            <span style={{ flexGrow: 1 }}>&nbsp;</span>
            <CompanySelector />
            <AppMenu />
            <TerritoriesMenu />
            <UserMenu />
            <LanguagePicker />
            <IconButton onClick={colorMode.toggleColorMode}>
              {theme.palette.mode === 'dark' ? (
                <Brightness7Icon sx={{ color: '#ffffff' }} />
              ) : (
                <Brightness4Icon sx={{ color: '#ffffff' }} />
              )}
            </IconButton>
          </Toolbar>
        </ThemeProvider>
      </Container>
    </AppBar>
  )
}
export default Layout
