import { Stack } from '@mui/material'
import { useDownloadDocument } from '@src/data/api/merchants-api/merchants-api'
import DownloadIcon from '@mui/icons-material/Download'

interface Props {
  fileName: string
  sasTokenUrl: string
}

const DownloadButton = ({ fileName, sasTokenUrl }: Props) => {
  const [downloadDocument] = useDownloadDocument(fileName, sasTokenUrl)

  const handleDownload = async () => {
    try {
      await downloadDocument()
    } catch (error) {
      console.error('Failed to download document:', error)
    }
  }

  return (
    <Stack direction="row" marginTop="-0.5rem" justifyContent="center">
      <DownloadIcon sx={{ color: 'grey', cursor: 'pointer' }} onClick={handleDownload} />
    </Stack>
  )
}

export default DownloadButton
