import CloseIcon from '@mui/icons-material/Close'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Stack } from '@mui/material'
import { AsyncActionButton } from '@src/components'
import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Territory, TerritoryRow } from '@src/data/types/Territory'
import { useUpdateTerritory } from '@src/data/api/territories-api/territories-api'

interface Props {
  open: boolean
  title: string
  handleClose: () => void
  row: TerritoryRow | null
}

const EditTerritoryDialog = ({ open, handleClose, row }: Props) => {
  const { t } = useTranslation()
  const [updateTerritory, isUpdatingTerritory, resetUpdateTerritory] = useUpdateTerritory()

  const { handleSubmit, reset, register } = useForm<Territory>({
    mode: 'onBlur',
  })

  const onSubmit = async (data: Territory) => {
    await updateTerritory(data).then((value) => {
      resetUpdateTerritory()
      if (value) handleClose()
    })
  }

  React.useEffect(() => {
    reset(row ? { ...row, id: String(row.id) } : { id: '', name: '' })
  }, [open, reset, row])

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
      transitionDuration={0}
    >
      <DialogTitle style={{ borderBottom: '1px solid' }} id="alert-dialog-title" sx={{ textAlign: 'center' }}>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose()
          }}
          style={{
            position: 'absolute',
            right: 15,
            top: 15,
          }}
        >
          <CloseIcon />
        </IconButton>
        {t('common.editTerritory')}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }} alignItems="flex-start">
          <Grid item xs={12} md={12}>
            <TextField id="name" label={t('common.name')} {...register('name', { required: true })} fullWidth />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid item width="100%">
          <Stack direction="row" spacing={2}>
            <AsyncActionButton
              fullWidth
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
              isPending={isUpdatingTerritory}
            >
              {t('common.save')}
            </AsyncActionButton>
            <AsyncActionButton fullWidth variant="outlined" onClick={() => handleClose()}>
              {t('common.cancel')}
            </AsyncActionButton>
          </Stack>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}

export default React.memo(EditTerritoryDialog)
