import { Edit as EditIcon } from '@mui/icons-material'
import DeleteIcon from '@mui/icons-material/Delete'
import { Alert, Box, CircularProgress, InputAdornment, TextField, Typography } from '@mui/material'
import IconButtonWithTooltip from '@src/components/IconButtonWithTooltip'
import SelectTerritoryDialog from '@src/containers/ViewMerchant/components/SelectTerritoryDialog'
import { useTerritoryById } from '@src/data/api/territories-api/territories-api'
import yup from '@src/data/types/common/yup-extended'
import { ESourceSystem } from '@src/data/types/Constants'
import { Merchant } from '@src/data/types/Merchant'
import { Territory } from '@src/data/types/Territory'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { InferType } from 'yup'

interface MerchantTerritoryProps {
  merchant: Merchant
}

export const MerchantTerritorySchema = yup.object().shape({
  territoryId: yup.string().defined().nullable().default(null),
})

const MerchantTerritory: React.FC<MerchantTerritoryProps> = ({ merchant }) => {
  const form = useFormContext<InferType<typeof MerchantTerritorySchema>>()
  const territoryId = form.watch('territoryId')
  const [openTerritoriesDialog, setOpenTerritoriesDialog] = useState(false)
  const [fetchedTerritory, isFetching, error] = useTerritoryById(territoryId)
  const { t } = useTranslation()

  // Cannot modify Monolith merchant territory while they are managed in the Monolith
  const canDelete = merchant?.territoryId !== null && merchant?.sourceSystem === ESourceSystem.alis

  const territoryName = fetchedTerritory?.name || t('merchant.noTerritory')

  const handleTerritorySelected = (selectedTerritory: Territory) => {
    if (!merchant) return
    form.setValue('territoryId', selectedTerritory.id, { shouldValidate: true, shouldDirty: true })
    setOpenTerritoriesDialog(false)
  }

  const handleClickOpen = () => {
    setOpenTerritoriesDialog(true)
  }

  const handleClose = () => {
    setOpenTerritoriesDialog(false)
  }

  const handleDeleteTerritory = () => {
    if (!merchant) return
    form.setValue('territoryId', null, { shouldValidate: true, shouldDirty: true })
  }

  return (
    <>
      {isFetching && <CircularProgress />}
      {error && <Alert severity="error">{error.message}</Alert>}

      {!isFetching && (
        <>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t('merchant.addTerritory')}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              sx={{ flexGrow: 1, minWidth: '20vh' }}
              label={t('common.territory')}
              value={territoryName}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButtonWithTooltip
                      disabled={merchant.sourceSystem === ESourceSystem.monolith}
                      onClick={handleClickOpen}
                      tooltip={t('common.editTerritory')}
                      icon={<EditIcon fontSize="medium" style={{ cursor: 'pointer' }} />}
                    />
                    <IconButtonWithTooltip
                      onClick={() => canDelete && handleDeleteTerritory()}
                      tooltip={t('common.deleteTerritory')}
                      icon={<DeleteIcon fontSize="medium" />}
                      disabled={!canDelete}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <SelectTerritoryDialog
            open={openTerritoriesDialog}
            title={t('merchant.addTerritory')}
            label={t('common.territory')}
            onConfirm={handleTerritorySelected}
            onCancel={handleClose}
            sourceSystem={merchant.sourceSystem}
          />
        </>
      )}
    </>
  )
}

export default MerchantTerritory
