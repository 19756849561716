import { Tab, Tabs } from '@mui/material'
import ReportsList from '@src/components/reportsList'
import TabPanel from '@src/components/TabPanel'
import useFeatureFlags from '@src/data/api/featureSwitch-api/featureSwitch-api'
import { useGetMerchantReports, useMerchantById, useMerchantUserList } from '@src/data/api/merchants-api/merchants-api'
import { ESourceSystem } from '@src/data/types/Constants'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Users from '../../components/userList'
import MerchantInformation from './merchantInformation'

const MerchantPage = () => {
  const { id } = useParams()
  const { t } = useTranslation()
  const [users, isFetchingUsers] = useMerchantUserList(id ?? '')
  const [shouldPoll, setShouldPoll] = useState(false)
  const [reportsSummary, isFetchingReports] = useGetMerchantReports(id ?? '', shouldPoll)
  const [merchant] = useMerchantById(id ?? '')
  const [selectedTabId, setSelectedTabId] = useState(0)
  const [flags, isFetchingFeatureFlags] = useFeatureFlags()

  const handleTabChange = (_event: React.ChangeEvent<object>, newValue: number) => {
    setSelectedTabId(newValue)
  }

  const handleGenerateNewReport = () => {
    setShouldPoll(true)
  }

  useEffect(() => {
    if (reportsSummary && reportsSummary.length > 0 && shouldPoll === true) {
      const latestReport = reportsSummary[0]
      const reportDate = new Date(latestReport.createdOn)

      // Check if the latest report is recent within the 10 seconds
      if (Date.now() - reportDate.getTime() < 10 * 1000) {
        setShouldPoll(false)
      }
    }
  }, [shouldPoll, reportsSummary])

  return (
    <>
      <MerchantInformation merchant={merchant!} />
      <div style={{ display: 'flex', justifyContent: 'left', marginLeft: '30px' }}>
        <Tabs value={selectedTabId} onChange={handleTabChange}>
          <Tab label={t('common.users')} />
          {merchant?.sourceSystem === ESourceSystem.alis && <Tab label={t('common.reports')} />}
        </Tabs>
      </div>
      <TabPanel value={selectedTabId} index={0}>
        <Users merchantId={id!} usersList={users} isPendingSearchUsers={isFetchingUsers} />
      </TabPanel>
      {!isFetchingFeatureFlags && flags?.useMerchantReports === true && (
        <TabPanel value={selectedTabId} index={1}>
          <ReportsList
            merchantId={id!}
            reportsSummary={reportsSummary}
            isFetchingReports={isFetchingReports || shouldPoll}
            onGenerateReport={handleGenerateNewReport}
          />
        </TabPanel>
      )}
    </>
  )
}

export default MerchantPage
