import DeleteIcon from '@mui/icons-material/Delete'
import {
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import { styled } from '@mui/material/styles'
import { useUpdateMerchantParent } from '@src/data/api/merchants-api/merchants-api'
import { Merchant } from '@src/data/types/Merchant'
import { UpdateParentDto } from '@src/data/types/UpdateParentDto'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SelectMerchantsDialog from './components/SelectMerchantsDialog'
import Footer from './components/footer'
import TableToolbar from './components/toolBar'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}))

interface Props {
  bannerId: string
  merchantsList: Merchant[]
  isFetchingMerchantsList: boolean
}

const Merchants = ({ bannerId, merchantsList, isFetchingMerchantsList }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [openUserDialog, setOpenUserDialog] = React.useState(false)
  const [filteredValue, setFilteredValue] = React.useState<string>('')
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(5)

  const [updateMerchantParent] = useUpdateMerchantParent()

  const merchantsFiltered = useMemo(() => {
    if (!merchantsList) return []

    return merchantsList.filter(
      (merchant) =>
        merchant.id.toLowerCase().includes(filteredValue.toLowerCase()) ||
        merchant.name.toLowerCase().includes(filteredValue.toLowerCase()) ||
        merchant.province.toLowerCase().includes(filteredValue.toLowerCase()),
    )
  }, [merchantsList, filteredValue])

  const handleSearch = useCallback((value: string) => {
    setFilteredValue(value)
  }, [])

  const closeMerchantDialog = useCallback(() => {
    setOpenUserDialog(false)
  }, [])

  const handleMerchantsSelected = async (newMerchants: Merchant[]) => {
    setOpenUserDialog(false)

    const promises: Promise<Merchant | void>[] = []

    newMerchants.forEach((merchant) => {
      if (merchant) {
        const dto: UpdateParentDto = {
          parentId: bannerId,
          merchantId: merchant.id,
        }

        promises.push(updateMerchantParent(dto))
      }
    })

    await Promise.all(promises)
  }

  const deleteMerchant = async (id: string) => {
    const dto: UpdateParentDto = {
      parentId: '',
      merchantId: id,
    }

    await updateMerchantParent(dto)
  }

  const handleOnClick = (merchantId: string) => {
    navigate(`/merchant/${merchantId}/view`)
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - merchantsFiltered.length) : 0

  const handleChangePage = (_event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newRowsPerPage = parseInt(event.target.value, 10)
    setRowsPerPage(newRowsPerPage)
    setPage(0)
  }

  return (
    <>
      <TableContainer component={Paper}>
        <TableToolbar handleAdd={setOpenUserDialog} handleSearch={handleSearch} title={t('common.merchant')} />
        <Table sx={{ maxHeight: '25rem' }} aria-label="custom pagination table">
          <TableHead>
            <TableRow>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>{t('common.fullName')}</StyledTableCell>
              <StyledTableCell align="right">{t('common.language')}</StyledTableCell>
              <StyledTableCell align="right">{t('common.province')}</StyledTableCell>
              <StyledTableCell align="right">{t('common.program')}</StyledTableCell>
              <StyledTableCell align="center">{t('common.website')}</StyledTableCell>
              <StyledTableCell align="right" />
            </TableRow>
          </TableHead>

          {isFetchingMerchantsList ? (
            <TableBody>
              <TableRow>
                <TableCell colSpan={7} style={{ padding: 0 }}>
                  <LinearProgress style={{ height: '10px' }} />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              {(rowsPerPage > 0
                ? merchantsFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : merchantsFiltered
              ).map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Typography
                      variant="body2"
                      component={Button}
                      onClick={() => {
                        handleOnClick(row.id)
                      }}
                    >
                      {row.id}
                    </Typography>
                  </TableCell>
                  <TableCell align="left">{row.name}</TableCell>
                  <TableCell align="right">{t(`common.languageId.${row.languageId}`)}</TableCell>
                  <TableCell align="right">{row.province}</TableCell>
                  <TableCell align="right">
                    {row.supportedFinancingPrograms.map((e) => t(`common.eFinancingProgram.${e}`)).join(', ')}
                  </TableCell>
                  <TableCell align="center">
                    <a href={row.website} target="_blank" rel="noopener noreferrer">
                      {row.website}
                    </a>
                  </TableCell>
                  <TableCell align="right">
                    <IconButton onClick={() => deleteMerchant(row.id)}>
                      <DeleteIcon sx={{ color: 'red' }} />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          )}

          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 15, 25]}
                count={merchantsFiltered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      'aria-label': 'rows per page',
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={Footer}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <SelectMerchantsDialog
        open={openUserDialog}
        title={t('merchant.addMerchant')}
        label={t('common.merchant')}
        allowMultiple
        onConfirm={handleMerchantsSelected}
        onCancel={closeMerchantDialog}
      />
    </>
  )
}

export default Merchants
