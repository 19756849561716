import { AxiosError } from 'axios'
import { createContext, useState, useCallback, useMemo, useContext } from 'react'
import { getUserToken, loadUserFromJwtString } from '@src/data/providersUtils/UserProviderUtils'
import { EUserRights } from '@src/data/types/Constants'
import { initApiClient } from '../data/api/api-client'
import { IcebergUser } from '../data/types/IcebergUser'
import { reportErrorToConsole } from '../services/error-logger'
import { getUser } from '../services/Authentification'

export interface TAppStore {
  user: IcebergUser | null
  reloadUser: () => void
}
export const AppContext = createContext<TAppStore | null>(null)

export function useAppStore() {
  const [user, setUser] = useState<IcebergUser | null>(getUser())

  const handleApiError = useCallback(
    (error: AxiosError) => {
      if (error.response?.status === 401) {
        setUser(null)
      }
      reportErrorToConsole(error)
    },
    [setUser],
  )

  initApiClient(handleApiError)

  const reloadUser = useCallback(() => {
    setUser(getUser())
    initApiClient(handleApiError)
  }, [setUser, handleApiError])

  return useMemo(
    () => ({
      user,
      reloadUser,
    }),
    [user, reloadUser],
  )
}

export function useUser() {
  const ctx = useContext(AppContext)
  return ctx?.user
}

export const hasUserRight = (requiredRight: EUserRights): boolean => {
  const jwtToken = getUserToken()
  const icebergUser = jwtToken ? loadUserFromJwtString(jwtToken) : null

  if (!icebergUser) {
    return false
  }

  return !!icebergUser.rights[requiredRight]
}
