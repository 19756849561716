import { AddCircleOutline as AddIcon } from '@mui/icons-material'
import { Box, IconButton, TextField, Toolbar, Tooltip, Typography } from '@mui/material'
import { debounce } from 'lodash-es'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  handleAdd: (e: boolean) => void
  handleSearch: (value: string) => void
}
const TableToolbar = ({ handleAdd, handleSearch }: Props) => {
  const { t } = useTranslation()

  const debounceOnChange = useMemo(() => debounce(handleSearch, 300), [handleSearch])

  React.useEffect(() => {
    return () => {
      debounceOnChange.cancel()
    }
  })

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography variant="h6" id="tableTitle" component="div">
        {t('common.user')}
      </Typography>
      <TextField
        sx={{ ml: 2, mr: 2, minWidth: '30vh' }}
        label={t('common.search')}
        placeholder={t('common.searchEmailName')}
        onChange={(e) => debounceOnChange(e.target.value.toLowerCase())}
      />
      <Box sx={{ flex: '1 1 100%' }} />
      <Tooltip title={t('profile.addUser')}>
        <IconButton onClick={() => handleAdd(true)}>
          <AddIcon color="primary" />
        </IconButton>
      </Tooltip>
    </Toolbar>
  )
}

export default TableToolbar
