import { useUser } from '@src/contexts/AppContext'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const GuestGuard = () => {
  const user = useUser()
  const navigate = useNavigate()

  useEffect(() => {
    if (user) {
      navigate('/', { replace: true })
    }
  }, [navigate, user])

  return !user ? <Outlet /> : null
}

export default GuestGuard
