import { useGetMerchantPaymentPlans, useUpdateMerchant } from '@src/data/api/merchants-api/merchants-api'
import { Merchant } from '@src/data/types/Merchant'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { reportErrorToConsole } from '@src/services/error-logger'
import { Button, Stack } from '@mui/material'
import { AsyncActionButton } from '@src/components'
import { DATE_MAX, EMerchantProperties } from '@src/data/types/Constants'
import { memo } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import useFeatureFlags from '@src/data/api/featureSwitch-api/featureSwitch-api'
import { InferType } from 'yup'
import { useNavigate } from 'react-router-dom'
import MerchantPropertiesPanel, { MerchantPropertiesSchema } from './MerchantPropertiesPanel'
import MerchantTagsPanel, { MerchantTagsSchema } from './MerchantTagsPanel'
import MerchantTerritory, { MerchantTerritorySchema } from './MerchantTerritory'
import MerchantRatesPanel from './MerchantRatesPanel'
import MerchantPromoPanel, { MerchantPromoSchema } from './MerchantPromoPanel'
import MerchantReportConfigPanel, { MerchantReportFormSchema } from './MerchantReportConfigPanel'

interface Props {
  merchant: Merchant
}

const configProptertyList = Object.values(EMerchantProperties)

const MerchantFormSchema = MerchantPropertiesSchema.concat(MerchantTagsSchema)
  .concat(MerchantTerritorySchema)
  .concat(MerchantPromoSchema)
  .concat(MerchantReportFormSchema)

type MerchantFormSchemaType = InferType<typeof MerchantFormSchema>
const MerchantForm = ({ merchant }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [updateMerchant, isUpdatingMerchant] = useUpdateMerchant()
  const [merchantPaymentPlans, isPaymentPlanFetching] = useGetMerchantPaymentPlans(merchant.id)
  const [flags, isFetchingFeatureFlags] = useFeatureFlags()

  const merchantDefaultProperties = configProptertyList.reduce(
    (acc, curr) => {
      acc[curr] = merchant[curr]
      return acc
    },
    {} as Record<EMerchantProperties, boolean>,
  )

  const form = useForm<MerchantFormSchemaType>({
    resolver: yupResolver(MerchantFormSchema),
    defaultValues: {
      tags: merchant.tags,
      territoryId: merchant.territoryId,
      defaultAppliedPromoCode: merchant.defaultAppliedPromoCode,
      reportGenerationFrequency: merchant.reportGenerationFrequency,
      reportRecipientsEmailList: merchant.reportRecipientsEmailList,
      ...merchantDefaultProperties,
    },
  })
  const { watch, formState } = form
  const defaultAppliedPromoCode = watch('defaultAppliedPromoCode')

  const isPlanValid = merchantPaymentPlans.some((plan) => {
    if (defaultAppliedPromoCode === null || defaultAppliedPromoCode === 'none') return true
    if (plan.promoCode !== defaultAppliedPromoCode) return false

    const today = new Date()
    const dateFrom = new Date(plan.activeFrom)
    const dateThrough = new Date(plan.expiresOn ?? DATE_MAX)

    return today >= dateFrom && today <= dateThrough
  })

  const hasMerchantPaymentPlan =
    merchantPaymentPlans.filter((plan) => {
      const today = new Date()
      const dateFrom = new Date(plan.activeFrom)
      const dateThrough = new Date(plan.expiresOn ?? DATE_MAX)
      return today >= dateFrom && today <= dateThrough
    }).length > 0 || merchant.defaultAppliedPromoCode != null

  const isSubmitDisabled = isUpdatingMerchant || !formState.isDirty || (!isPlanValid && hasMerchantPaymentPlan)

  const onSubmit = (data: MerchantFormSchemaType) => {
    updateMerchant({
      territoryId: data.territoryId,
      noPaymentPolicy: data.noPaymentPolicy,
      canSkipFlinks: data.canSkipFlinks,
      canPayBorrowers: data.canPayBorrowers,
      automaticIncomeApproval: data.automaticIncomeApproval,
      merchantId: merchant.id,
      tags: data.tags,
      defaultAppliedPromoCode: data.defaultAppliedPromoCode === 'none' ? null : data.defaultAppliedPromoCode,
      reportGenerationFrequency:
        data.reportGenerationFrequency === 'none' || !data.reportGenerationFrequency
          ? ''
          : data.reportGenerationFrequency,
      reportRecipientsEmailList: data.reportRecipientsEmailList,
    })
      .then(() => {
        form.reset(data)
        navigate(`/merchant/${merchant.id}/view`)
      })
      .catch(reportErrorToConsole)
  }

  return (
    <FormProvider {...form}>
      <Stack gap={2} component="form" onSubmit={form.handleSubmit(onSubmit)}>
        <MerchantTerritory merchant={merchant} />
        <MerchantTagsPanel />
        <MerchantRatesPanel merchant={merchant} />
        {!isPaymentPlanFetching && hasMerchantPaymentPlan && (
          <MerchantPromoPanel availablePaymentPlans={merchantPaymentPlans} isPlanValid={isPlanValid} />
        )}
        {!isFetchingFeatureFlags && flags?.useMerchantReports === true && <MerchantReportConfigPanel />}
        <MerchantPropertiesPanel />
        <Stack direction="row" justifyContent="end" spacing={2}>
          <Button variant="contained" color="primary" href={`/merchant/${merchant.id}/view`}>
            {t('common.back')}
          </Button>
          <AsyncActionButton
            variant="contained"
            color="primary"
            type="submit"
            isPending={isUpdatingMerchant}
            disabled={isSubmitDisabled}
          >
            {t('common.save')}
          </AsyncActionButton>
        </Stack>
      </Stack>
    </FormProvider>
  )
}

export default memo(MerchantForm)
