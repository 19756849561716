import { Tab, Tabs } from '@mui/material'
import TabPanel from '@src/components/TabPanel'
import Users from '@src/components/userList'
import { useMerchantById, useMerchantUserList, useMerchantsList } from '@src/data/api/merchants-api/merchants-api'
import { EBannerType } from '@src/data/types/Constants'
import { MerchantFilter } from '@src/data/types/Filter'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import BannerInformation from './bannerInformation'
import Merchants from './merchantList'

const BannerPage = () => {
  const { t } = useTranslation()
  const { id } = useParams()

  const [selectedBannerTypeId, setSelectedBannerTypeId] = useState(EBannerType.user)
  const MERCHANTS_LIMIT = 1000

  const query: MerchantFilter = React.useMemo(() => {
    return {
      parentId: id!,
      limit: MERCHANTS_LIMIT,
      offset: 0,
    } as MerchantFilter
  }, [id])

  const [users, isFetchingUsers] = useMerchantUserList(id!)
  const [banner] = useMerchantById(id!)
  const [merchantsList, isFetchingMerchantsList] = useMerchantsList(query)

  const handleTabChange = (_event: React.ChangeEvent<object>, newValue: number) => {
    setSelectedBannerTypeId(newValue)
  }

  return (
    <>
      <BannerInformation merchant={banner!} />

      <div style={{ display: 'flex', justifyContent: 'left', marginLeft: '30px' }}>
        <Tabs value={selectedBannerTypeId} onChange={handleTabChange}>
          <Tab label={t('common.user')} />
          <Tab label={t('common.merchant')} />
        </Tabs>
      </div>

      <TabPanel value={selectedBannerTypeId} index={EBannerType.user}>
        <Users merchantId={id!} usersList={users} isPendingSearchUsers={isFetchingUsers} />
      </TabPanel>

      <TabPanel value={selectedBannerTypeId} index={EBannerType.merchant}>
        <Merchants bannerId={id!} merchantsList={merchantsList} isFetchingMerchantsList={isFetchingMerchantsList} />
      </TabPanel>
    </>
  )
}

export default BannerPage
