import { useUser } from '@src/contexts/AppContext'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

const AuthGuard = () => {
  const user = useUser()
  const navigate = useNavigate()
  useEffect(() => {
    if (!user) {
      navigate('/login', { replace: true })
    }
  }, [navigate, user])

  return <Outlet />
}

export default AuthGuard
