import React from 'react'
import { FormControlLabel, Checkbox, Box, Typography, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EMerchantProperties } from '@src/data/types/Constants'
import { Controller, useFormContext } from 'react-hook-form'
import { switchOnChangeAdapter } from '@src/services/mui-form-adapter'
import yup from '@src/data/types/common/yup-extended'
import { InferType } from 'yup'

const propertiesConfig = [
  {
    key: EMerchantProperties.noPaymentPolicy,
    titleKey: 'merchant.noPaymentPolicy.title',
    descriptionKey: 'merchant.noPaymentPolicy.description',
  },
  {
    key: EMerchantProperties.canSkipFlinks,
    titleKey: 'merchant.canSkipFlinks.title',
    descriptionKey: 'merchant.canSkipFlinks.description',
  },
  {
    key: EMerchantProperties.canPayBorrowers,
    titleKey: 'merchant.canPayBorrowers.title',
    descriptionKey: 'merchant.canPayBorrowers.description',
  },
  {
    key: EMerchantProperties.forcePaymentToBorrower,
    titleKey: 'merchant.forcePaymentToBorrower.title',
    descriptionKey: 'merchant.forcePaymentToBorrower.description',
  },
  {
    key: EMerchantProperties.automaticIncomeApproval,
    titleKey: 'merchant.automaticIncomeApproval.title',
    descriptionKey: 'merchant.automaticIncomeApproval.description',
  },
] as const

const propertyConfigValidator = yup.boolean().required().default(false)

export const MerchantPropertiesSchema = yup.object().shape(
  propertiesConfig.reduce(
    (acc, curr) => {
      acc[curr.key] = propertyConfigValidator
      return acc
    },
    {} as Record<EMerchantProperties, typeof propertyConfigValidator>,
  ),
)

const MerchantPropertiesPanel: React.FC = () => {
  const { t } = useTranslation()
  const { control, getValues } = useFormContext<InferType<typeof MerchantPropertiesSchema>>()

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        {t('merchant.properties')}
      </Typography>

      <Stack gap={1}>
        <Controller
          key={propertiesConfig[0].key}
          name={propertiesConfig[0].key}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} {...field} onChange={switchOnChangeAdapter(onChange)} />}
              label={
                <Box>
                  <Typography>{t(propertiesConfig[0].titleKey)}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t(propertiesConfig[0].descriptionKey)}
                  </Typography>
                </Box>
              }
            />
          )}
        />
        <Controller
          key={propertiesConfig[1].key}
          name={propertiesConfig[1].key}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} {...field} onChange={switchOnChangeAdapter(onChange)} />}
              label={
                <Box>
                  <Typography>{t(propertiesConfig[1].titleKey)}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t(propertiesConfig[1].descriptionKey)}
                  </Typography>
                </Box>
              }
            />
          )}
        />
        <Controller
          key={propertiesConfig[2].key}
          name={propertiesConfig[2].key}
          disabled={getValues(propertiesConfig[3].key) === true}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} {...field} onChange={switchOnChangeAdapter(onChange)} />}
              label={
                <Box>
                  <Typography>{t(propertiesConfig[2].titleKey)}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t(propertiesConfig[2].descriptionKey)}
                  </Typography>
                </Box>
              }
            />
          )}
        />
        <Controller
          key={propertiesConfig[3].key}
          name={propertiesConfig[3].key}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} disabled {...field} onChange={switchOnChangeAdapter(onChange)} />}
              label={
                <Box>
                  <Typography>{t(propertiesConfig[3].titleKey)}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t(propertiesConfig[3].descriptionKey)}
                  </Typography>
                </Box>
              }
            />
          )}
        />
        <Controller
          key={propertiesConfig[4].key}
          name={propertiesConfig[4].key}
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <FormControlLabel
              control={<Checkbox checked={value} {...field} onChange={switchOnChangeAdapter(onChange)} />}
              label={
                <Box>
                  <Typography>{t(propertiesConfig[4].titleKey)}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t(propertiesConfig[4].descriptionKey)}
                  </Typography>
                </Box>
              }
            />
          )}
        />
      </Stack>
    </Box>
  )
}

export default MerchantPropertiesPanel
